import { Redirect } from '@mna/react'
import LoginForm from './LoginForm'
import WideContent from 'templates/WideContent'

export default ({ state, actions }) =>
  state.user && state.user.id ? <Redirect to="/" /> :
    <WideContent>
      <div
        className="mr-auto" style={{ maxWidth: '360px' }}
      >
        <h1 className="mt-1 mb-2 text-center">Welcome</h1>
        <LoginForm {...{ actions }}/>
      </div>
    </WideContent>
