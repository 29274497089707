import { Component, Link, classnames as cx } from '@mna/react'
import getFormData from '@mna/dom/getFormData'

export default class LoginForm extends Component {

  state = {
    formData: {
      name: '',
      password: ''
    },
    invalidFields: {},
    resultMessage: ''
  }

  onSubmit(e, action = 'login' /* register */) {

    const { actions } = this.props

    e.preventDefault()

    const formData = getFormData(this.el)
    const invalid = {}

    Object.keys(formData).forEach(key => {
      if (!formData[key]) invalid[key] = true
    })

    this.setState({ formData, invalidFields: invalid, resultMessage: '' })

    if (Object.keys(invalid).length) return

    actions[action]({ data: formData })
      .then(result => {
        if (!result) {
          this.setState({ resultMessage: 'Failed' })
          return
        }
        // Redirects on success
        //this.setState({ resultMessage: 'Success!' })
      })

  }

  render() {

    const { formData, invalidFields, resultMessage } = this.state

    return (
      <form
        ref={el => this.el = el}
        onSubmit={e => this.onSubmit(e)}
      >

        <input type="text" name="name" placeholder="User name"
          className={cx(invalidFields.name ? 'is-error' : '',
            'mb-2'
          )}
          defaultValue={formData.name}
        />

        <input type="password" name="password" placeholder="Password"
          className={cx(invalidFields.password ? 'is-error' : '',
            'mb-2'
          )}
          defaultValue={formData.password}
        />

        <button type="submit" className="btn bg-blue color-white w-100">Login</button>

        {/* <button type="button" className="btn bg-white float-right"
          onClick={e => {
            this.onSubmit(e, 'register')
          }}
        >Register</button> */}

        <p className="color-dark-gray">{resultMessage}</p>
      </form>
    )
  }
}
